<template>
    <div class="jinhui_home_model">
    </div>
</template>
<script>
    export default {
        name: 'feiChiHome',
        data() {
            return {
                screenWidth: document.body.clientWidth,
                scale: document.body.clientWidth / 1920
            }
        },
        mounted() {
            // const that = this
            window.onload = function () {
                adjust()
                window.onresize = function () {
                    adjust()
                }
            }
            let adjust = function () {
                let winWidth = document.documentElement.clientWidth
                // console.log(winWidth)
                let body = document.getElementsByTagName('body')[0]
                // let winHeight = document.body.clientHeight
                // 获取屏幕的宽度 和高度
                let scale = winWidth / 1920
                // // console.log(scale)
                body.style.cssText = 'transform:scale(' + scale + ');'
            }
        },
        watch: {
            /* 监听*/
            screenWidth(val) {
                this.screenWidth = val;
                // console.log("this.screenWidth", this.screenWidth)
            }
        },
        computed: {
            scaleFun: function () {
                var scale = this.scale;
                return `transform:scale(${scale})`
            }
        }
    }
</script>
<style lang="less">
    .jinhui_home_model {
        width: 1920px;
        height: 1080px;
        color: #fff;
        position: relative;
    }
</style>